
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import { get } from "@/api";
import { Article } from "@/interfaces/blog";

@Component({
  metaInfo: {
    title: "Article",
  },
})
export default class ArticleView extends Vue {
  articleId: string = this.$route.params.id;
  article: null | Article = null;
  Articles: null | Article[] = null;
  loaded = false;
  loadedArticle = false;
  maxRange = 5;
  error: null | object = null;
  async fetchArticle() {
    try {
      const { data } = await get.article(this.$route.params.id);
      this.article = data;
      this.loadedArticle = true;
    } catch (error) {
      this.article = null;
      this.loadedArticle = false;
    }
  }
  async fetchArticles() {
    try {
      const { data } = await get.articles();
      this.Articles = data;
      this.loaded = true;
    } catch (error) {
      this.Articles = null;
      this.loaded = false;
    }
  }
  // Publication date
  renderData(date: number) {
    const Data = new Date(date);
    return Data.toLocaleDateString();
  }

  // Make sure that currently displaying article is not in recommendation
  removeCurrentArticle(array: any[]) {
    const list = array.filter(
      (element: any) => !(element.id == this.$route.params.id)
    );
    return list;
  }
  seeMore() {
    this.maxRange += 4;
  }
  created() {
    this.fetchArticles();
    this.fetchArticle();
  }
}
